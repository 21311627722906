import React from "react"
import Async from 'react-async'
import { Lazy, Pagination, Navigation, Mousewheel, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import State from "./State"

import "swiper/css"
import "swiper/css/lazy"
import "swiper/css/pagination"
import "swiper/css/navigation"
import StickyNav from "./StickyNav"

const loadPhotos = async ( { extid, object, workorderid, caseid }) => {
    let url
    if (object === 'case') {
        url = `/api/photos/case/?extid=${extid}`
    } else if (object === 'lineitem') {
        url = `/api/photos/lineitem/?extid=${extid}`
    } else if (object === 'caseworkorder') {
        url = `/api/photos/maintenance/?caseid=${caseid}&workorderid=${workorderid}`
    }
    const res = await fetch(url)
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

function View() {
  
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('extid')
    const obj = queryParams.get('object')
    const name = queryParams.get('name')
    const caseid = queryParams.get('caseid')
    const workorderid = queryParams.get('workorderid')
    document.title = `Maintenance Photos (${name})`
    return (
        <>
        <StickyNav/>
        { (!id && !obj) || (!obj && !caseid && !workorderid) ?
            <State status="error" title="Internal Server Error" message="id and object is required."/>
            :
            <Async promiseFn={loadPhotos} extid={id} object={obj} workorderid={workorderid} caseid={caseid}>
                <Async.Loading><State status="info" title="Loading ..." message="Fetching data, please wait!"/></Async.Loading>
                <Async.Rejected>
                    {error => <State status="error" title="Error" message="Something went wrong."/> }
                </Async.Rejected>
                <Async.Fulfilled>
                    {data => {
                        return (
                            <Swiper keyboard={{ enabled: true, }} mousewheel={true} lazy={true} pagination={true} navigation={true} modules={[Keyboard, Lazy, Pagination, Navigation, Mousewheel]} id="viewSwiper">
                                {!data.length > 0 && data ?
                                    <SwiperSlide key="no-image">
                                        <State status="error" title="Error" message="No Photos Found."/>
                                    </SwiperSlide>
                                : data.map(photo=> (
                                    <SwiperSlide key={photo.Id}>
                                        <img data-src={photo.Image_URL__c} src={photo.Image_URL__c} alt={photo.Description__c} className="swiper-lazy"/>
                                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )
                    }}
                </Async.Fulfilled>
            </Async>
        }
        </>
    )
}

export default View