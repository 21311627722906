import React, { Component } from "react"
import { Container, Navbar } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

class Header extends Component {
    render() {
        return(
            <Navbar expand="lg" className="topNav">
                <Container>
                    <Navbar.Brand href="/" style={ {color:"white"} }>
                        Home Services
                    </Navbar.Brand>
                </Container>
            </Navbar>
        )
    }
}

export default Header