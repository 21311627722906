import React, { Component } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
class Animation extends Component {
    render() {
        return(
            <>
                <div id="poplar-logo-loader" className="h-100 d-flex justify-content-center align-items-center">
                    <svg id="poplar" width="100%" height="100%" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" fill="#FFF">
                        <defs></defs>
                        <g transform="matrix(1.001066, 0, 0, 1, -2.091434, -13.367615)">
                            <text x="41.5" y="266.069" id="homeservices-text">Home Services</text>
                            <text x="316.862" y="300.63" id="by-poplarhomes-text">by Poplar</text>
                            <path d="M 446.053 273.054 L 433.723 285.672 C 433.255 286.151 432.993 286.8 432.993 287.478 L 432.993 301.397 L 462.146 301.397 L 462.146 283.877 C 462.146 283.068 461.773 282.308 461.14 281.827 L 449.291 272.809 C 448.301 272.058 446.924 272.161 446.053 273.054"></path>
                        </g>
                    </svg>
                    <div id="loader-container">
                        <div className="fixed-main-loader">
                            <div className="loader">
                                <svg id="home-loader" viewBox="0 0 200 200" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Page-1" stroke="none" fill="none" transform="matrix(1.760811, 0, 0, 1.812915, -658.891541, 1.125314)">
                                        <g id="Poplar-Logo-Home" transform="translate(0.000000, 0.000000)" fill="#FF7300">
                                            <path d="M 425.079 2.206 L 377.044 50.061 C 375.221 51.877 374.198 54.341 374.198 56.912 L 374.198 109.699 L 487.782 109.699 L 487.782 43.252 C 487.782 40.188 486.329 37.304 483.864 35.48 L 437.696 1.284 C 433.842 -1.571 428.476 -1.178 425.079 2.206" id="house"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Animation